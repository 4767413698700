import { Injectable } from '@angular/core';
import { LibObject } from 'src/lib/lib-models/lib-model-object.model';
import { LibFormsSelector } from 'src/lib/lib-forms/models/lib-forms.model';
import { ChoicesStoreSettings } from '../models/choices-store.model';

@Injectable({
  providedIn: 'root',
})
export class ChoicesStoreParseService {
  public setValues<T extends LibFormsSelector>(
    apiData: T[],
    settings: ChoicesStoreSettings
  ): T[] {
    let parsedData: LibObject[] = [...apiData];

    if (settings.parsing) {
      parsedData = settings.parsing(parsedData);
    }

    parsedData = parsedData.map((data: LibObject) => {
      return {
        ...data,
        value: settings.valueId ? data[settings.valueId] : '',
        name: settings.nameId ? data[settings.nameId] : '',
      };
    });

    return parsedData as T[];
  }

  public sortValues<T extends LibFormsSelector>(
    apiData: T[],
    settings: ChoicesStoreSettings
  ): T[] {
    if (settings.sortId) {
      const sortId = settings.sortId;

      return apiData.sort((a: LibObject, b: LibObject) =>
        a[sortId] > b[sortId] ? 1 : -1
      );
    } else {
      return apiData;
    }
  }

  public addChoice<T extends LibFormsSelector>(
    choices: T[],
    newChoice: T[] | null,
    choiceValue: string | number,
    indexId: string
  ): T[] {
    const removedOldChoices = choices.filter(
      (choice: LibObject) => choice[indexId] !== choiceValue
    );
    return newChoice ? [...removedOldChoices, ...newChoice] : removedOldChoices;
  }
}

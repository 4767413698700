import { Injectable, inject } from '@angular/core';

import {
  LibTableDisplayed,
  LibTableDisplaySettings,
} from 'src/lib/lib-table/models/lib-table.model';
import { UserService } from 'src/app/_user/user.service';
import { LibTableFilter } from 'src/lib/lib-table/components/lib-table-filters/models/lib-table-filters.model';

import {
  UserTableFilter,
  UserTableFilterApi,
  UserTableFilterCreateApi,
  UserTableFilterTableListApi,
} from '../models/user-table-filter.model';

@Injectable({
  providedIn: 'root',
})
export class UserTableFilterParseService {
  private user = inject(UserService).$user;

  public parseFilters(
    filterApis: UserTableFilterApi[],
    tables: UserTableFilterTableListApi[]
  ): UserTableFilter[] {
    return filterApis.map((filter) =>
      this.parseFilter(
        filter,
        tables.find((table) => table.tableId === filter.tableId)?.tableName || ''
      )
    );
  }

  public parseFilter(
    filterApi: UserTableFilterApi,
    tableName: string
  ): UserTableFilter {
    const userFilter: LibTableDisplaySettings = JSON.parse(filterApi?.settings);
    return {
      ...filterApi,
      filters: userFilter?.filters,
      displayed: userFilter?.displayed,
      tableName: tableName,
    };
  }

  public createAddData(
    name: string,
    tableId: number,
    filters: LibTableFilter[],
    displayed: LibTableDisplayed[]
  ): UserTableFilterCreateApi {
    return {
      filterName: name,
      tableId: tableId,
      settings: this.stringifyFilters(filters, displayed),
      defaultFilter: false,
      isActive: true,
      ownerId: this.user().id || 0,
    };
  }

  private stringifyFilters(
    filters: LibTableFilter[],
    displayed: LibTableDisplayed[]
  ): string {
    return JSON.stringify({
      filters: filters,
      displayed: displayed,
    });
  }
}

import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiRequestService } from 'src/app/_shared/api-request/api-request.service';

import {
  UserTableFilterApi,
  UserTableFilterTableListApi,
} from '../models/user-table-filter.model';
import {
  USER_TABLE_FILTER_FILTER_URL,
  USER_TABLE_FILTER_TABLE_URL,
} from '../models/user-table-filter-config.model';

@Injectable({
  providedIn: 'root',
})
export class UserTableFilterGetService {
  private apiRequest = inject(ApiRequestService);

  public getFilters$(): Observable<UserTableFilterApi[]> {
    return this.apiRequest.get$<UserTableFilterApi[]>(
      USER_TABLE_FILTER_FILTER_URL
    );
  }

  public getTables$(): Observable<UserTableFilterTableListApi[]> {
    return this.apiRequest.get$<UserTableFilterTableListApi[]>(
      USER_TABLE_FILTER_TABLE_URL
    );
  }
}

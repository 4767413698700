import { Injectable, computed, inject, signal } from '@angular/core';

import {
  UserTableFilterApi,
  UserTableFilterTableListApi,
} from '../models/user-table-filter.model';
import { UserTableFilterParseService } from './user-table-filter-parse.service';

@Injectable({
  providedIn: 'root',
})
export class UserTableFilterDataService {
  private userTableFilterParse = inject(UserTableFilterParseService);
  public $userTableFiltersApi = signal<UserTableFilterApi[]>([]);
  public $userTableFilterTables = signal<UserTableFilterTableListApi[]>([]);

  public $userTableFilters = computed(() =>
    this.userTableFilterParse.parseFilters(
      this.$userTableFiltersApi(),
      this.$userTableFilterTables()
    )
  );

  public upsert(updatedFilter: UserTableFilterApi): void {
    this.$userTableFiltersApi.update((userTableFilters) => [
      ...userTableFilters.filter(
        (userTableFilter) => userTableFilter.filterId !== updatedFilter.filterId
      ),
      updatedFilter,
    ]);
    return;
  }

  public upserts(updatedFilters: UserTableFilterApi[]): void {
    this.$userTableFiltersApi.update((userTableFilters) => [
      ...userTableFilters.filter(
        (userTableFilter) =>
          !updatedFilters.some(
            (updatedFilter) => userTableFilter.filterId === updatedFilter.filterId
          )
      ),
      ...updatedFilters,
    ]);
    return;
  }

  public delete(filterIds: number[]): void {
    this.$userTableFiltersApi.update((userTableFilters) => [
      ...userTableFilters.filter(
        (userTableFilter) => !filterIds.some((id) => id === userTableFilter.filterId)
      ),
    ]);
    return;
  }
}

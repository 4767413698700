import { Injectable, computed, inject } from '@angular/core';
import { Observable, map, of, switchMap } from 'rxjs';
import { LibFormsSelector } from '../lib-forms/models/lib-forms.model';
import { LibStoreService } from '../lib-store/lib-store.service';
import { LibChoicesParseService } from './services/lib-choices-parse.service';

@Injectable({
  providedIn: 'root',
})
export class LibChoicesService<
  T extends LibFormsSelector,
> extends LibStoreService<T> {
  private libChoicesParse = inject(LibChoicesParseService);

  public $choices = computed(() =>
    this.libChoicesParse.setValuesAndSort(this.$store(), this.$settings()),
  );

  public load$(): Observable<void> {
    return this.$store().length === 0 ? this.reload$() : of(undefined);
  }

  public reload$(): Observable<void> {
    return this.reloadAll$().pipe(map(() => undefined));
  }

  public addChoice$(choice: Partial<T>): Observable<void> {
    return this.add$(choice as T).pipe(switchMap(() => this.reload$()));
  }

  public updateChoice$(
    choiceId: number,
    choiceUpdate: Partial<T>,
  ): Observable<void> {
    return this.update$(choiceId, choiceUpdate).pipe(
      switchMap(() => this.reload$()),
    );
  }

  public getByValue(value: string | number | null): T | null {
    if (value === null) {
      return null;
    }
    return this.$choices().find((choice) => choice.value === value) || null;
  }

  public getByName(name: string | null): T | null {
    if (name === null) {
      return null;
    }
    return this.$choices().find((choice) => choice.name === name) || null;
  }
}

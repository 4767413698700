import { Injectable } from '@angular/core';

import { User } from 'src/app/_user/models/user.model';
import { LibChoicesSettings } from 'src/lib/lib-choices/models/lib-choices.model';

import {
  COIN_TYPE_ADMIN_URL,
  COIN_TYPE_GET_URL,
  COIN_TYPE_SETTINGS,
} from '../models/coin-type-config.model';

@Injectable({
  providedIn: 'root',
})
export class CoinTypeParseService {
  public generateSettings(user: User): LibChoicesSettings {
    switch (true) {
      case user.isAdmin:
        return {
          ...COIN_TYPE_SETTINGS,
          getAllUrl: `${user.corePath}${COIN_TYPE_GET_URL}`,
          addUrl: `${user.corePath}${COIN_TYPE_ADMIN_URL}`,
          updateUrl: `${user.corePath}${COIN_TYPE_ADMIN_URL}`,
        };
      default:
        return {
          ...COIN_TYPE_SETTINGS,
          getAllUrl: `${user.corePath}${COIN_TYPE_GET_URL}`,
        };
    }
  }
}

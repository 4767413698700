import { Injectable, inject, signal } from '@angular/core';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';

import { LibObject } from 'src/lib/lib-models/lib-model-object.model';
import { LibFormsSelector } from 'src/lib/lib-forms/models/lib-forms.model';

import { ChoicesStoreSettings } from './models/choices-store.model';
import { ChoicesStoreAddService } from './services/choices-store-add.service';
import { ChoicesStoreGetService } from './services/choices-store-get.service';
import { ChoicesStoreParseService } from './services/choices-store-parse.service';
import { ChoicesStorePatchService } from './services/choices-store-patch.service';

@Injectable({
  providedIn: 'root',
})
export abstract class ChoicesStoreService<T extends LibFormsSelector> {
  private choicesStoreParse = inject(ChoicesStoreParseService);
  private choicesStoreGet = inject(ChoicesStoreGetService);
  private choicesStoreAdd = inject(ChoicesStoreAddService);
  private choicesStorePatch = inject(ChoicesStorePatchService);

  private $_choices = signal<T[]>([]);
  readonly $choices = this.$_choices.asReadonly();

  abstract choicesSettings: ChoicesStoreSettings;

  public getByValue(value: string | number | null): T | null {
    if (value === null) {
      return null;
    }
    return this.$choices().find((choice) => choice.value === value) || null;
  }

  public getByName(name: string | null): T | null {
    if (name === null) {
      return null;
    }
    return this.$choices().find((choice) => choice.name === name) || null;
  }

  public load$(): Observable<void> {
    return this.$choices().length === 0 ? this.reload$() : of(undefined);
  }

  public reload$(): Observable<void> {
    return this.choicesStoreGet.get$<T>(this.choicesSettings).pipe(
      tap((loadedChoices) => this.$_choices.set(loadedChoices)),
      map(() => undefined),
      catchError(() => of(undefined))
    );
  }

  public reset$(): Observable<void> {
    return of(this.$_choices.set([]));
  }

  public _add$<T>(choice: T): Observable<void> {
    return this.choicesStoreAdd
      .add$<T>(this.choicesSettings, choice)
      .pipe(switchMap(() => this.reload$()));
  }

  public addChoice$<T>(choice: T): Observable<void> {
    return this._add$<T>(choice);
  }

  public _update$<T>(
    choiceId: number,
    choiceUpdate: Partial<T>
  ): Observable<void> {
    return this.choicesStorePatch
      .update$<Partial<T>>(this.choicesSettings, choiceId, choiceUpdate)
      .pipe(switchMap(() => this.reload$()));
  }

  public updateChoice$<T>(
    choiceId: number,
    choiceUpdate: Partial<T>
  ): Observable<void> {
    return this._update$<Partial<T>>(choiceId, choiceUpdate);
  }

  public loadSelectedChoice$(
    url: string,
    choiceValue: string | number,
    indexId: string
  ): Observable<void> {
    return this.$choices().find(
      (choice: LibObject) => choice[indexId] === choiceValue
    )
      ? of(undefined)
      : this.reloadSelectedChoice$(url, choiceValue, indexId);
  }

  public reloadSelectedChoice$(
    url: string,
    choiceValue: string | number,
    indexId: string
  ): Observable<void> {
    return this.choicesStoreGet.getChoice$<T>(url, this.choicesSettings).pipe(
      map((loadedChoice) =>
        this.choicesStoreParse.addChoice(
          this.$choices(),
          loadedChoice || [],
          choiceValue,
          indexId
        )
      ),
      map((loadedChoices) =>
        this.choicesStoreParse.sortValues(loadedChoices, this.choicesSettings)
      ),
      tap((loadedChoices) => this.$_choices.set(loadedChoices)),
      map(() => undefined)
    );
  }
}

import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LibHttpService } from '../../lib-http/lib-http.service';
import { LibHttp, LibHttpTypes } from '../../lib-http/models/lib-http.model';
import { LibStoreSettings } from '../models/lib-store.model';
import { LibStoreParseService } from './lib-store-parse.service';

@Injectable({
  providedIn: 'root',
})
export class LibStoreAddService {
  private libHttp = inject(LibHttpService);
  private libStoreParse = inject(LibStoreParseService);

  public add$<T>(
    settings: LibStoreSettings,
    record: Partial<T>
  ): Observable<T> {
    if (settings.addUrl) {
      const httpSettings: LibHttp = {
        url: settings.addUrl,
        type: LibHttpTypes.POST,
        data: this.libStoreParse.parseApiData<T>(
          record,
          settings.updateParsing
        ),
        httpOptions: settings.libHttpOptions,
      };
      return this.libHttp
        .http$<T>(httpSettings, settings.libHttpOptionsSettings)
        .pipe(
          map((apiData) => {
            if (apiData) {
              return this.libStoreParse.parseApiData<T>(
                apiData,
                settings.loadParsing
              );
            } else {
              throw new Error();
            }
          })
        );
    } else {
      throw new Error('Missing Url');
    }
  }
}

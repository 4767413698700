import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LibHttpService } from '../../lib-http/lib-http.service';
import { LibHttp, LibHttpTypes } from '../../lib-http/models/lib-http.model';
import { LibStoreSettings } from '../models/lib-store.model';
import { LibStoreParseService } from './lib-store-parse.service';

@Injectable({
  providedIn: 'root',
})
export class LibStorePatchService {
  private libHttp = inject(LibHttpService);
  private libStoreParse = inject(LibStoreParseService);

  public update$<T>(
    settings: LibStoreSettings,
    id: number | string,
    updatedRecord: Partial<T>,
    snackBarMessage: string | null = null
  ): Observable<T> {
    if (settings.updateUrl) {
      const httpSettings: LibHttp = {
        url: `${settings.updateUrl}/${id}`,
        type: LibHttpTypes.PATCH,
        data: this.libStoreParse.parseApiData<T>(
          updatedRecord,
          settings.updateParsing
        ),
        httpOptions: settings.libHttpOptions,
      };
      return this.libHttp
        .http$<T>(httpSettings, {
          ...settings.libHttpOptionsSettings,
          snackBarMessage: snackBarMessage,
        })
        .pipe(
          map((apiData) => {
            if (apiData) {
              return this.libStoreParse.parseApiData<T>(
                apiData,
                settings.loadParsing
              );
            } else {
              throw new Error();
            }
          })
        );
    } else {
      throw new Error();
    }
  }

  public updateByCustomUrl$<T, U>(
    url: string,
    settings: LibStoreSettings,
    preParse?: Function | null,
    parse?: Function | null,
    postData?: U | null
  ): Observable<T[]> {
    const libHttp: LibHttp = {
      url: url,
      type: LibHttpTypes.PATCH,
      data: postData,
      httpOptions: settings.libHttpOptions,
    };

    return this.libHttp
      .http$<T[]>(libHttp, settings.libHttpOptionsSettings)
      .pipe(
        map((apiData) => {
          if (apiData) {
            const preProcessedData = preParse
              ? this.libStoreParse.parseApiData(apiData, preParse)
              : apiData;
            const checkedApiData = Array.isArray(preProcessedData)
              ? preProcessedData
              : [preProcessedData];
            return this.libStoreParse.parseApiDataArray<T>(
              checkedApiData,
              parse || settings.loadParsing
            );
          } else {
            return [];
          }
        })
      );
  }
}

import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';

import { ApiRequestService } from 'src/app/_shared/api-request/api-request.service';

import { PatchDoc, UserTableFilter } from '../models/user-table-filter.model';
import { UserTableFilterDataService } from './user-table-filter-data.service';
import { USER_TABLE_FILTER_FILTER_URL } from '../models/user-table-filter-config.model';

@Injectable({
  providedIn: 'root',
})
export default class UserTableFilterUpdateService {
  private apiRequest = inject(ApiRequestService);
  private userTableFilterData = inject(UserTableFilterDataService);
  private $userTableFilters = this.userTableFilterData.$userTableFilters;

  public setDefault$(tableName: string, name: string): Observable<void> {
    //Get Current default and new default
    const userFilterToUpdate = this.$userTableFilters().filter(
      (userTableFilter) =>
        (userTableFilter.tableName === tableName &&
          userTableFilter.defaultFilter) ||
        (userTableFilter.filterName === name &&
          userTableFilter.tableName === tableName)
    );

    const flipDefaults$ = userFilterToUpdate.map((userTableFilter) => {
      const defaultChange: Partial<UserTableFilter> = {
        defaultFilter: !userTableFilter.defaultFilter,
      };

      return this.updateDefault$(userTableFilter.filterId, defaultChange);
    });

    return forkJoin(flipDefaults$).pipe(
      map((filters) =>
        this.userTableFilterData.upserts(
          filters.map((filter) => {
            return filter;
          })
        )
      )
    );
  }

  private updateDefault$(
    id: number,
    updatedFilter: Partial<UserTableFilter>
  ): Observable<UserTableFilter> {
    const PatchDoc: PatchDoc = {
      op: 'replace',
      path: 'defaultFilter',
      value: updatedFilter.defaultFilter,
    };
    return this.apiRequest.patch$<UserTableFilter, [PatchDoc]>(
      `${USER_TABLE_FILTER_FILTER_URL}${id}`,
      [PatchDoc]
    );
  }
}

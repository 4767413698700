import { Injectable } from '@angular/core';
import { LibStoreIndex } from '../models/lib-store.model';

@Injectable({
  providedIn: 'root',
})
export class LibStoreParseService {
  public parseApiDataArray<T>(
    apiData: T[],
    parseFunction?: Function | null
  ): T[] {
    return parseFunction
      ? apiData.map((record) => parseFunction(record))
      : apiData;
  }

  public parseApiData<T>(
    apiData: Partial<T>,
    parseFunction?: Function | null
  ): T {
    return parseFunction ? parseFunction(apiData) : apiData;
  }

  public setIndexRecords<T>(
    records: T[],
    indexFieldName: string
  ): LibStoreIndex[] {
    return records?.map((record: any) => {
      {
        return {
          id: record[indexFieldName],
          lastUpdated: new Date(),
        };
      }
    });
  }

  public updatedNeeded(
    indexValue: string | number,
    indexArray: LibStoreIndex[],
    minutes?: number
  ): boolean {
    if (minutes === -1) {
      return false;
    }

    const lastUpdated = indexArray.find(
      (index) => index.id === indexValue
    )?.lastUpdated;
    return (
      !lastUpdated ||
      new Date(lastUpdated.getTime() + (minutes || 5) * 60000).valueOf() <
        new Date().valueOf()
    );
  }

  public updatedNeededArray(
    indexValues: string[] | number[],
    indexArray: LibStoreIndex[],
    minutes?: number
  ): boolean {
    return indexValues
      .map((index) => this.updatedNeeded(index, indexArray, minutes))
      .some((value) => value);
  }
}

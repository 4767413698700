import { Injectable, inject } from '@angular/core';
import { Observable, map, of, switchMap, tap } from 'rxjs';

import { LibTableDisplayed } from 'src/lib/lib-table/models/lib-table.model';
import { ApiRequestService } from 'src/app/_shared/api-request/api-request.service';
import { LibTableFilter } from 'src/lib/lib-table/components/lib-table-filters/models/lib-table-filters.model';

import {
  UserTableFilterApi,
  UserTableFilterCreateApi,
  UserTableFilterTableCreate,
  UserTableFilterTableListApi,
} from '../models/user-table-filter.model';
import {
  USER_TABLE_FILTER_FILTER_URL,
  USER_TABLE_FILTER_TABLE_URL,
} from '../models/user-table-filter-config.model';
import { UserTableFilterGetService } from './user-table-filter-get.service';
import { UserTableFilterDataService } from './user-table-filter-data.service';
import { UserTableFilterParseService } from './user-table-filter-parse.service';

@Injectable({
  providedIn: 'root',
})
export class UserTableFilterAddService {
  private userTableFilterGet = inject(UserTableFilterGetService);
  private apiRequest = inject(ApiRequestService);
  private userTableFilterData = inject(UserTableFilterDataService);
  private userTableFilterStoreParse = inject(UserTableFilterParseService);
  private $userTableFilterTables =
    this.userTableFilterData.$userTableFilterTables;

  public filter$(
    name: string,
    tableName: string,
    filtered: LibTableFilter[],
    displayed: LibTableDisplayed[]
  ): Observable<void> {
    let userSettingId =
      this.$userTableFilterTables().find(
        (table) => table.tableName === tableName
      )?.tableId || null;

    if (userSettingId) {
      return this.addFilter$(name, userSettingId, filtered, displayed);
    } else {
      return this.getTableIdFromTableList$(tableName).pipe(
        switchMap((userSettingData) =>
          userSettingData ? of(userSettingData) : this.createTable$(tableName)
        ),
        switchMap((userSettingData) =>
          this.addFilter$(name, userSettingData, filtered, displayed)
        )
      );
    }
  }

  private getTableIdFromTableList$(
    tableName: string
  ): Observable<number | null> {
    return this.userTableFilterGet.getTables$().pipe(
      tap((tables) => this.$userTableFilterTables.set(tables)),
      map((tables) => {
        return (
          tables.find((table) => table.tableName === tableName)?.tableId || null
        );
      })
    );
  }

  private createTable$(tableName: string): Observable<number> {
    const createTableData = {
      tableName: tableName,
      isActive: true,
    };

    return this.apiRequest
      .post$<UserTableFilterTableListApi, UserTableFilterTableCreate>(
        USER_TABLE_FILTER_TABLE_URL,
        createTableData
      )
      .pipe(
        map((createTableApi) => {
          this.$userTableFilterTables.update((userTableFilterTables) => [
            ...userTableFilterTables,
            createTableApi,
          ]);
          return createTableApi.tableId;
        })
      );
  }

  private addFilter$(
    name: string,
    userSettings: number,
    filters: LibTableFilter[],
    displayed: LibTableDisplayed[]
  ): Observable<void> {
    const postData = this.userTableFilterStoreParse.createAddData(
      name,
      userSettings,
      filters,
      displayed
    );

    return this.apiRequest
      .post$<UserTableFilterApi, UserTableFilterCreateApi>(
        USER_TABLE_FILTER_FILTER_URL,
        postData
      )
      .pipe(
        tap((filter) => this.userTableFilterData.upsert(filter)),
        map(() => undefined)
      );
  }
}

import { USER_API_PATH } from 'src/app/_shared/_models/api.model';
import { ChoicesStoreSettings } from 'src/app/_shared/choices-store/models/choices-store.model';

import { CoinFields } from './coin-field.model';

export const COIN_SETTINGS: ChoicesStoreSettings = {
  getUrl: `${USER_API_PATH}/coin`,
  addUrl: null,
  updateUrl: null,
  valueId: CoinFields.COIN_ID,
  nameId: CoinFields.COIN_ID,
  sortId: CoinFields.COIN_ID,
};

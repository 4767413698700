import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';

import { CoinService } from './coin/coin.service';
import { ChoicesList } from './_models/choices-enum.model';
import { CoinTypeService } from './coin-type/coin-type.service';

@Injectable({
  providedIn: 'root',
})
export class ChoicesService {
  public coin = inject(CoinService);
  public coinType = inject(CoinTypeService);

  public loadChoices$(choicesToLoad: ChoicesList[]): Observable<void[]> {
    const choicesLoadRequest$ = choicesToLoad.map((loadChoice) =>
      this[loadChoice].load$()
    );
    return choicesLoadRequest$.length > 0
      ? forkJoin(choicesLoadRequest$)
      : of([]);
  }

  public reloadChoices$(choicesToLoad: ChoicesList[]): Observable<void[]> {
    const choicesLoadRequest$ = choicesToLoad.map((loadChoice) =>
      this[loadChoice].reload$()
    );
    return choicesLoadRequest$.length > 0
      ? forkJoin(choicesLoadRequest$)
      : of([]);
  }

  public reset$(choicesToLoad: ChoicesList[]): Observable<any[]> {
    const choicesLoadRequest$ = choicesToLoad.map((loadChoice) =>
      this[loadChoice].reset$()
    );

    return of([]);
  }
}

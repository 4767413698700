import { Injectable, inject } from '@angular/core';

import { LibHttp, LibHttpTypes } from 'src/lib/lib-http/models/lib-http.model';

import { UserService } from 'src/app/_user/user.service';
import { ApiRequestOptions } from '../models/api-request.model';
import { CORE_PAGINATION_SIZE } from '../models/api-request-enum.model';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestParseService {
  private $user = inject(UserService).$user;

  public generateHttp<U>(
    httpType: LibHttpTypes,
    url: string,
    options?: ApiRequestOptions | null,
    dataObject?: U,
  ): LibHttp {
    const httpConfig: LibHttp = {
      url: this.generateUrl(url, options),
      type: httpType,
    };

    switch (httpType) {
      case LibHttpTypes.GET:
      case LibHttpTypes.DELETE:
        return httpConfig;
      default:
        return { ...httpConfig, data: dataObject };
    }
  }

  public generateCorePageUrl(
    url: string,
    page: number,
    paginationSize?: number | null,
  ): string {
    const coreUrl = this.generateCoreUrl(url);
    const parameterCharacter = coreUrl.includes('?') ? '&' : '?';

    return `${coreUrl}${parameterCharacter}pageSize=${
      paginationSize || CORE_PAGINATION_SIZE.CORE_NORMAL_PAGE
    }&pageNumber=${page}`;
  }

  public generateUrl(url: string, options?: ApiRequestOptions | null): string {
    switch (true) {
      case !!options?.doNotParseUrl:
        return url;
      case !options?.paginationSize:
        return this.generateCoreUrl(url);
      default:
        return this.generateCorePageUrl(
          url,
          options?.paginationPage || 1,
          options?.paginationSize,
        );
    }
  }

  private generateCoreUrl(url: string): string {
    return `${this.$user().corePath}${url}`;
  }
}

import { LibChoicesSettings } from 'src/lib/lib-choices/models/lib-choices.model';
import { ADMIN_API_PATH, USER_API_PATH } from 'src/app/_shared/_models/api.model';

import { CoinFields } from '../../coin/models/coin-field.model';

export const COIN_TYPE_GET_URL = `${USER_API_PATH}/coin-type`;
export const COIN_TYPE_ADMIN_URL = `${ADMIN_API_PATH}/coin-type`;

export const COIN_TYPE_SETTINGS: LibChoicesSettings = {
  indexFieldName: CoinFields.COIN_ID,
  valueId: CoinFields.COIN_ID,
  nameId: CoinFields.COIN_ID,
  reloadTimeInMinutes: -1,
};

import { Injectable, inject } from '@angular/core';
import { Observable, catchError, forkJoin, map, of, tap } from 'rxjs';

import { UserTableFilterGetService } from './user-table-filter-get.service';
import { UserTableFilterDataService } from './user-table-filter-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserTableFilterLoadService {
  private userTableFilterData = inject(UserTableFilterDataService);
  private userTableFilterGet = inject(UserTableFilterGetService);
  private $userTableFiltersApi = this.userTableFilterData.$userTableFiltersApi;
  private $userTableFilterTables =
    this.userTableFilterData.$userTableFilterTables;

  public load$(): Observable<void> {
    return this.$userTableFiltersApi().length === 0
      ? this.reload$()
      : of(undefined);
  }

  public reload$(): Observable<void> {
    return forkJoin([
      this.userTableFilterGet.getFilters$().pipe(catchError(() => of([]))),
      this.userTableFilterGet.getTables$().pipe(catchError(() => of([]))),
    ]).pipe(
      tap((combinedData) => this.$userTableFilterTables.set(combinedData[1])),
      tap((combinedData) => this.$userTableFiltersApi.set(combinedData[0])),
      map(() => undefined)
    );
  }
}

import { Injectable, computed, inject, signal } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { LibObject } from '../lib-models/lib-model-object.model';
import { LIB_STORE_START_SETTINGS } from './models/lib-store-config.model';
import { LibStoreIndex } from './models/lib-store.model';
import { LibStoreAddService } from './services/lib-store-add.service';
import { LibStoreDeleteService } from './services/lib-store-delete.service';
import { LibStoreGetService } from './services/lib-store-get.service';
import { LibStoreParseService } from './services/lib-store-parse.service';
import { LibStorePatchService } from './services/lib-store-patch.service';

@Injectable({
  providedIn: 'root',
})
export abstract class LibStoreService<T> {
  private libStoreGet = inject(LibStoreGetService);
  private libStoreAdd = inject(LibStoreAddService);
  private libStorePatch = inject(LibStorePatchService);
  private libStoreParse = inject(LibStoreParseService);
  private libStoreDelete = inject(LibStoreDeleteService);

  private $_libStore = signal<T[]>([]);
  private $_libStoreIndex = signal<LibStoreIndex[]>([]);

  public readonly $store = this.$_libStore.asReadonly();
  public readonly $index = this.$_libStoreIndex.asReadonly();
  public $settings = computed(() => LIB_STORE_START_SETTINGS);

  public getById(recordId: string | number | null): T | null {
    return recordId
      ? this.$store().find(
          (storeRecord) =>
            (storeRecord as LibObject)[this.$settings().indexFieldName] ===
            recordId,
        ) || null
      : null;
  }

  public getByCustom(
    selectedValue: (string | number) | (string | number)[],
    selectedFieldName: string,
  ): T[] {
    if (Array.isArray(selectedValue)) {
      return this.$store().filter((record) =>
        selectedValue.includes((record as LibObject)[selectedFieldName]),
      );
    } else {
      return this.$store().filter(
        (record) => (record as LibObject)[selectedFieldName] === selectedValue,
      );
    }
  }

  public add$(newRecord: Partial<T>): Observable<T> {
    return this.libStoreAdd.add$<T>(this.$settings(), newRecord).pipe(
      tap((record) => {
        this._updateStore(
          record ? [record] : [],
          (record as LibObject)[this.$settings().indexFieldName],
          this.$settings().indexFieldName,
        );
      }),
    );
  }

  public delete$(id: string | number): Observable<void> {
    return this.libStoreDelete.delete$(this.$settings(), id).pipe(
      tap(() => {
        this._updateStore([], id, this.$settings().indexFieldName);
      }),
    );
  }

  public update$(
    recordId: number | string,
    recordUpdate: Partial<T>,
    snackBarMessage: string | null = null,
  ): Observable<T> {
    return this.libStorePatch
      .update$<T>(this.$settings(), recordId, recordUpdate, snackBarMessage)
      .pipe(
        tap((record) => {
          this._updateStore(
            record ? [record] : [],
            (record as LibObject)[this.$settings().indexFieldName],
            this.$settings().indexFieldName,
          );
        }),
      );
  }

  public loadAll$(): Observable<T[]> {
    const store = this.$store();
    return store.length > 0 &&
      !this.libStoreParse.updatedNeededArray(
        store.map(
          (record) => (record as LibObject)[this.$settings().indexFieldName],
        ),
        this.$_libStoreIndex(),
        this.$settings().reloadTimeInMinutes,
      )
      ? of(store)
      : this.reloadAll$();
  }

  public reloadAll$(): Observable<T[]> {
    return this.libStoreGet.getAll$<T>(this.$settings()).pipe(
      tap((records) => {
        this.$_libStore.set(records || []);
      }),
    );
  }

  public loadById$(recordId: string | number): Observable<T | null> {
    const settings = this.$settings();
    const record = this.getById(recordId);

    return record &&
      !this.libStoreParse.updatedNeeded(
        (record as LibObject)[settings.indexFieldName],
        this.$_libStoreIndex(),
        settings.reloadTimeInMinutes,
      )
      ? of(record)
      : this.reloadById$(recordId);
  }

  public reloadById$(recordId: string | number): Observable<T | null> {
    return this.libStoreGet.getById$<T>(recordId, this.$settings()).pipe(
      tap((record) => {
        this._updateStore(
          record ? [record] : [],
          recordId,
          this.$settings().indexFieldName,
        );
      }),
    );
  }

  public loadByCustom$<U>(
    url: string,
    selectedValue: (string | number) | (string | number)[],
    selectedFieldName: string,
    indexes?: (string | number)[] | null,
    preParse?: Function | null,
    parse?: Function | null,
    postData?: U | null,
  ): Observable<T[]> {
    const records = this._getReadyData(
      selectedValue,
      selectedFieldName,
      indexes,
    );

    return !!records
      ? of(records)
      : this.reloadByCustom$(
          url,
          selectedValue,
          selectedFieldName,
          preParse,
          parse,
          postData,
        );
  }

  public reloadByCustom$<U>(
    url: string,
    selectedValue?: (string | number) | (string | number)[] | null,
    selectedFieldName?: string | null,
    preParse?: Function | null,
    parse?: Function | null,
    postData?: U | null,
  ): Observable<T[]> {
    return this.libStoreGet
      .getByCustomUrl$<T, U>(url, this.$settings(), preParse, parse, postData)
      .pipe(
        tap((records) => {
          this._updateStore(
            records,
            selectedValue || null,
            selectedFieldName || null,
          );
        }),
      );
  }

  public updateByCustom$<U>(
    url: string,
    selectedValue?: (string | number) | (string | number)[] | null,
    selectedFieldName?: string | null,
    preParse?: Function | null,
    parse?: Function | null,
    postData?: U | null,
  ): Observable<T[]> {
    return this.libStorePatch
      .updateByCustomUrl$<
        T,
        U
      >(url, this.$settings(), preParse, parse, postData)
      .pipe(
        tap((records) => {
          this._updateStore(
            records,
            selectedValue || null,
            selectedFieldName || null,
          );
        }),
      );
  }

  public _updateStore(
    newRecords: T[] | null,
    selectedValue: (string | number) | (string | number)[] | null,
    selectedFieldName: string | null,
  ): void {
    let cleanedStore: T[] = [];
    const indexFieldName = this.$settings().indexFieldName;
    const newIndex = this.libStoreParse.setIndexRecords<T>(
      newRecords || [],
      indexFieldName,
    );
    const newRecordIds = newIndex.map((index) => index.id);
    if (selectedFieldName && selectedValue) {
      if (Array.isArray(selectedValue)) {
        cleanedStore = this.$store().filter(
          (record) =>
            !selectedValue.includes((record as LibObject)[selectedFieldName]) &&
            !newRecordIds.includes((record as LibObject)[indexFieldName]),
        );
      } else {
        cleanedStore = this.$store().filter(
          (record) =>
            (record as LibObject)[selectedFieldName] !== selectedValue &&
            !newRecordIds.includes((record as LibObject)[indexFieldName]),
        );
      }
    }

    cleanedStore = cleanedStore.filter(
      (record) => !newIndex.includes((record as LibObject)[indexFieldName]),
    );

    const cleanedStoreIndex = cleanedStore.map(
      (record) => (record as LibObject)[indexFieldName],
    );

    const cleanedIndex = this.$_libStoreIndex().filter((index) =>
      cleanedStoreIndex.includes(index.id),
    );

    this.$_libStore.set([...cleanedStore, ...(newRecords || [])]);
    this.$_libStoreIndex.set([...cleanedIndex, ...newIndex]);
  }

  public _getReadyData(
    selectedValue: (string | number) | (string | number)[],
    selectedFieldName: string,
    indexes?: (string | number)[] | null,
  ): T[] | null {
    const storeIndexes = this.$_libStoreIndex().map(
      (storeIndex) => storeIndex.id,
    );
    const indexFieldName = this.$settings().indexFieldName;
    const forceReload = !indexes?.every((index) =>
      storeIndexes.includes(index),
    );
    const records = this.getByCustom(selectedValue, selectedFieldName);

    const timeReload = this.libStoreParse.updatedNeededArray(
      records.map((record) => (record as LibObject)[indexFieldName]),
      this.$_libStoreIndex(),
      this.$settings().reloadTimeInMinutes,
    );

    return records && !forceReload && !timeReload ? records : null;
  }
}
import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';

import { ApiRequestService } from 'src/app/_shared/api-request/api-request.service';

import { UserTableFilterDataService } from './user-table-filter-data.service';
import { USER_TABLE_FILTER_FILTER_URL } from '../models/user-table-filter-config.model';

@Injectable({
  providedIn: 'root',
})
export class UserTableFilterDeleteService {
  private apiRequest = inject(ApiRequestService);
  private userTableFilterData = inject(UserTableFilterDataService);
  private userTableFilters = this.userTableFilterData.$userTableFilters;

  public deleteFilters$(names: string[], tableName: string): Observable<void> {
    const filterIdsToDelete = this.userTableFilters()
      .filter(
        (userTableFilter) =>
          userTableFilter.tableName === tableName &&
          names.some((name) => userTableFilter.filterName === name)
      )
      .map((userTableFilter) => userTableFilter.filterId);

    const deleteArray = filterIdsToDelete.map((id) => {
      return this.delete$(id);
    });

    return forkJoin(deleteArray).pipe(
      map((deletedIds) => this.userTableFilterData.delete(deletedIds))
    );
  }

  public delete$(id: number): Observable<number> {
    return this.apiRequest.delete$(`${USER_TABLE_FILTER_FILTER_URL}${id}`).pipe(
      map(() => {
        return id;
      })
    );
  }
}

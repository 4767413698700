import { Injectable, inject } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { ApiRequestService } from 'src/app/_shared/api-request/api-request.service';
import { ChoicesStoreSettings } from '../models/choices-store.model';

@Injectable({
  providedIn: 'root',
})
export class ChoicesStorePatchService {
  private apiRequest = inject(ApiRequestService);

  public update$<T>(
    settings: ChoicesStoreSettings,
    id: number,
    choice: Partial<T>,
  ): Observable<void> {
    if (settings.updateUrl) {
      return this.apiRequest
        .patch$<undefined, Partial<T>>(`${settings.updateUrl}/${id}`, choice)
        .pipe(map(() => undefined));
    } else {
      return of(undefined);
    }
  }
}

import { Injectable, inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';

import { LibHttpService } from 'src/lib/lib-http/lib-http.service';
import { LibHttp, LibHttpTypes } from 'src/lib/lib-http/models/lib-http.model';

import { ApiRequestOptions } from '../models/api-request.model';
import { ApiRequestParseService } from './api-request-parse.service';
import { CORE_PAGINATION_SIZE } from '../models/api-request-enum.model';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestGetService {
  private libHttp = inject(LibHttpService);
  private apiRequestParse = inject(ApiRequestParseService);

  public get$<T>(
    url: string,
    options?: ApiRequestOptions | null
  ): Observable<T> {
    const httpData = this.apiRequestParse.generateHttp(
      LibHttpTypes.GET,
      url,
      options
    );

    return this.libHttp.http$<T>(httpData, options);
  }

  public getWithPagination$<T>(
    url: string,
    options?: ApiRequestOptions
  ): Observable<T[]> {
    const checkedOptions: ApiRequestOptions = options?.paginationSize
      ? options
      : { ...options, paginationSize: CORE_PAGINATION_SIZE.CORE_NORMAL_PAGE };

    let httpData = this.apiRequestParse.generateHttp(
      LibHttpTypes.GET,
      url,
      checkedOptions
    );

    return this.libHttp.httpResponse$<T[]>(httpData, checkedOptions).pipe(
      switchMap((response) =>
        this.createGetPaginationHttp$(response, httpData, url, checkedOptions)
      ),
      map((joinedData) => joinedData.flat())
    );
  }

  private createGetPaginationHttp$<T>(
    response: HttpResponse<T[]>,
    httpData: LibHttp,
    url: string,
    options?: ApiRequestOptions
  ): Observable<T[][]> {
    const pages =
      JSON.parse(response.headers.get('X-Pagination') || '{}')
        ?.TotalPageCount || 0;

    const firstPageData: T[] = response.body || [];

    let httpCall$: Observable<T[]>[] = [of(firstPageData)];

    for (let page = 2; page <= pages; page++) {
      const currentHttpData = {
        ...httpData,
        url: this.apiRequestParse.generateCorePageUrl(
          url,
          page,
          options?.paginationSize
        ),
      };
      httpCall$ = [
        ...httpCall$,
        this.libHttp.http$<T[]>(currentHttpData, options),
      ];
    }

    return forkJoin(httpCall$);
  }

  public put$<T>(
    url: string,
    dataObject: T,
    options?: ApiRequestOptions
  ): Observable<T> {
    const httpData = this.apiRequestParse.generateHttp(
      LibHttpTypes.PUT,
      url,
      options,
      dataObject
    );
    return this.libHttp.http$<T>(httpData, options);
  }

  public post$<T, U>(
    url: string,
    dataObject: U,
    options?: ApiRequestOptions
  ): Observable<T> {
    const httpData = this.apiRequestParse.generateHttp(
      LibHttpTypes.POST,
      url,
      options,
      dataObject
    );
    return this.libHttp.http$<T>(httpData, options);
  }

  public postWithPagination$<T, U>(
    url: string,
    dataObject: U,
    options?: ApiRequestOptions
  ): Observable<T[]> {
    const checkedOptions: ApiRequestOptions = options?.paginationSize
      ? options
      : { ...options, paginationSize: CORE_PAGINATION_SIZE.CORE_NORMAL_PAGE };

    const httpData = this.apiRequestParse.generateHttp(
      LibHttpTypes.POST,
      url,
      options,
      dataObject
    );

    return this.libHttp.httpResponse$<T[]>(httpData, checkedOptions).pipe(
      switchMap((response) =>
        this.createGetPaginationHttp$(response, httpData, url, checkedOptions)
      ),
      map((joinedData) => joinedData.flat())
    );
  }

  public patch$<T, U>(
    url: string,
    dataObject: U,
    options?: ApiRequestOptions
  ): Observable<T> {
    const httpData = this.apiRequestParse.generateHttp(
      LibHttpTypes.PATCH,
      url,
      options,
      dataObject
    );
    return this.libHttp.http$<T>(httpData, options);
  }

  public delete$<T>(url: string, options?: ApiRequestOptions): Observable<T> {
    const httpData = this.apiRequestParse.generateHttp(
      LibHttpTypes.DELETE,
      url,
      options
    );
    return this.libHttp.http$<T>(httpData, options);
  }
}

import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiRequestOptions } from './models/api-request.model';
import { ApiRequestGetService } from './services/api-request-get.service';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestService {
  private apiRequestGet = inject(ApiRequestGetService);

  public get$<T>(
    url: string,
    options?: ApiRequestOptions | null
  ): Observable<T> {
    return this.apiRequestGet.get$<T>(url, options);
  }

  public getWithPagination$<T>(
    url: string,
    options?: ApiRequestOptions
  ): Observable<T[]> {
    return this.apiRequestGet.getWithPagination$<T>(url, options);
  }

  public put$<T>(
    url: string,
    dataObject: T,
    options?: ApiRequestOptions
  ): Observable<T> {
    return this.apiRequestGet.put$<T>(url, dataObject, options);
  }

  public post$<T, U>(
    url: string,
    dataObject: U,
    options?: ApiRequestOptions
  ): Observable<T> {
    return this.apiRequestGet.post$<T, U>(url, dataObject, options);
  }

  public postWithPagination$<T, U>(
    url: string,
    dataObject: U,
    options?: ApiRequestOptions
  ): Observable<T[]> {
    return this.apiRequestGet.postWithPagination$<T, U>(
      url,
      dataObject,
      options
    );
  }

  public patch$<T, U>(
    url: string,
    dataObject: U,
    options?: ApiRequestOptions
  ): Observable<T> {
    return this.apiRequestGet.patch$<T, U>(url, dataObject, options);
  }

  public delete$<T>(url: string, options?: ApiRequestOptions): Observable<T> {
    return this.apiRequestGet.delete$<T>(url, options);
  }
}

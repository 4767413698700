import { computed, inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { UserService } from 'src/app/_user/user.service';
import { LibChoicesService } from 'src/lib/lib-choices/lib-choices.service';

import { CoinType } from './models/coin-type.model';
import { CoinTypeParseService } from './services/coin-type-parse.service';

@Injectable({
  providedIn: 'root',
})
export class CoinTypeService extends LibChoicesService<CoinType> {
  private coinTypeParse = inject(CoinTypeParseService);
  private $user = inject(UserService).$user;

  public override $settings = computed(() =>
    this.coinTypeParse.generateSettings(this.$user())
  );

  public $coinTypes = computed(() =>
    this.$choices().filter((coinType) => coinType.isActive)
  );

  public reset$(): Observable<any[]> {
    return of([]);
  }
}

import { Injectable } from '@angular/core';
import { LibFormsSelector } from '../../lib-forms/models/lib-forms.model';
import { LibObject } from '../../lib-models/lib-model-object.model';
import { LibChoicesSettings } from '../models/lib-choices.model';

@Injectable({
  providedIn: 'root',
})
export class LibChoicesParseService {
  public setValuesAndSort<T extends LibFormsSelector>(
    apiData: T[],
    settings: LibChoicesSettings
  ): T[] {
    return this.sortValues(this.setValues(apiData, settings), settings);
  }

  public setValues<T extends LibFormsSelector>(
    apiData: T[],
    settings: LibChoicesSettings
  ): T[] {
    return apiData.map((data) => {
      return {
        ...data,
        value: settings.valueId ? (data as LibObject)[settings.valueId] : '',
        name: settings.nameId ? (data as LibObject)[settings.nameId] : '',
      };
    });
  }

  public sortValues<T extends LibFormsSelector>(
    apiData: T[],
    settings: LibChoicesSettings
  ): T[] {
    if (settings.sortId) {
      const sortId = settings.sortId;

      return apiData.sort((a: LibObject, b: LibObject) =>
        a[sortId] > b[sortId] ? 1 : -1
      );
    } else {
      return apiData;
    }
  }
}

import { Injectable, inject } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { ApiRequestService } from 'src/app/_shared/api-request/api-request.service';
import { ChoicesStoreSettings } from '../models/choices-store.model';

@Injectable({
  providedIn: 'root',
})
export class ChoicesStoreAddService {
  private apiRequest = inject(ApiRequestService);

  public add$<T>(settings: ChoicesStoreSettings, choice: T): Observable<void> {
    if (settings.addUrl) {
      return this.apiRequest
        .post$<undefined, T>(settings.addUrl, choice)
        .pipe(map(() => undefined));
    } else {
      return of(undefined);
    }
  }
}

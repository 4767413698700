import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { LibTableDisplayed } from 'src/lib/lib-table/models/lib-table.model';
import { LibTableFilter } from 'src/lib/lib-table/components/lib-table-filters/models/lib-table-filters.model';

import { UserTableFilterAddService } from './services/user-table-filter-add.service';
import { UserTableFilterDataService } from './services/user-table-filter-data.service';
import { UserTableFilterLoadService } from './services/user-table-filter-load.service';
import UserTableFilterUpdateService from './services/user-table-filter-update.service';
import { UserTableFilterDeleteService } from './services/user-table-filter-delete.service';

@Injectable({
  providedIn: 'root',
})
export class UserTableFilterService {
  private userTableFilter = inject(UserTableFilterDataService);
  private userTableFilterAdd = inject(UserTableFilterAddService);
  private userTableFilterLoad = inject(UserTableFilterLoadService);
  private userTableFilterDelete = inject(UserTableFilterDeleteService);
  private UserTableFilterUpdate = inject(UserTableFilterUpdateService);

  public $userTableFilters = this.userTableFilter.$userTableFilters;

  public addFilter$(
    name: string,
    tableName: string,
    filtered: LibTableFilter[],
    displayed: LibTableDisplayed[]
  ): Observable<void> {
    return this.userTableFilterAdd.filter$(
      name,
      tableName,
      filtered,
      displayed
    );
  }

  public load$(): Observable<void> {
    return this.userTableFilterLoad.load$();
  }

  public reload$(): Observable<void> {
    return this.userTableFilterLoad.reload$();
  }

  public deleteFilters$(names: string[], tableName: string): Observable<void> {
    return this.userTableFilterDelete.deleteFilters$(names, tableName);
  }

  public setDefault$(tableName: string, name: string): Observable<void> {
    return this.UserTableFilterUpdate.setDefault$(tableName, name);
  }
}

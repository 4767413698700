import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LibFormsSelector } from 'src/lib/lib-forms/models/lib-forms.model';
import { ApiRequestService } from 'src/app/_shared/api-request/api-request.service';
import { ChoicesStoreSettings } from '../models/choices-store.model';
import { ChoicesStoreParseService } from './choices-store-parse.service';

@Injectable({
  providedIn: 'root',
})
export class ChoicesStoreGetService {
  private apiRequest = inject(ApiRequestService);
  private choicesStoreParse = inject(ChoicesStoreParseService);

  public get$<T extends LibFormsSelector>(
    settings: ChoicesStoreSettings,
  ): Observable<T[]> {
    const httpOptions = settings.httpOptions;
    const apiCall$ =
      httpOptions && httpOptions.paginationSize
        ? this.apiRequest.getWithPagination$<T>(
            settings.getUrl,
            settings.httpOptions,
          )
        : this.apiRequest.get$<T[]>(settings.getUrl, settings.httpOptions);

    return apiCall$.pipe(
      map((apiData) => this.choicesStoreParse.setValues<T>(apiData, settings)),
      map((apiData) => this.choicesStoreParse.sortValues<T>(apiData, settings)),
    );
  }

  public getChoice$<T extends LibFormsSelector>(
    url: string,
    settings: ChoicesStoreSettings,
  ): Observable<T[] | null> {
    return this.apiRequest.get$<T | T[]>(url, settings.httpOptions).pipe(
      map((apiData) => {
        const checkedApiData = Array.isArray(apiData) ? apiData : [apiData];
        return apiData
          ? this.choicesStoreParse.setValues<T>(checkedApiData, settings)
          : null;
      }),
    );
  }
}

import { Injectable, inject } from '@angular/core';

import { UserTableFilterService } from './user-table-filters/user-table-filter.service';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  public userTableFilter = inject(UserTableFilterService);
}

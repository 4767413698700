import { computed, Injectable } from '@angular/core';

import { ChoicesStoreService } from 'src/app/_shared/choices-store/choices-store.service';

import { Coin } from './models/coin.model';
import { COIN_SETTINGS } from './models/coin-config.model';

@Injectable({
  providedIn: 'root',
})
export class CoinService extends ChoicesStoreService<Coin> {
  public choicesSettings = COIN_SETTINGS;
  public $coins = computed(() => this.$choices().filter(row => row.coinId <= 200));
}

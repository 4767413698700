import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LibHttpService } from '../../lib-http/lib-http.service';
import { LibHttp, LibHttpTypes } from '../../lib-http/models/lib-http.model';
import { LibStoreSettings } from '../models/lib-store.model';

@Injectable({
  providedIn: 'root',
})
export class LibStoreDeleteService {
  private libHttp = inject(LibHttpService);

  public delete$<T>(
    settings: LibStoreSettings,
    id: number | string
  ): Observable<void> {
    if (settings.deleteUrl) {
      const httpSettings: LibHttp = {
        url: `${settings.deleteUrl}/${id}`,
        type: LibHttpTypes.DELETE,
        httpOptions: settings.libHttpOptions,
      };
      return this.libHttp
        .http$<T>(httpSettings, {
          ...settings.libHttpOptionsSettings,
          snackBarMessage: `Deleted ${settings.storeName} #${id} `,
        })
        .pipe(map(() => undefined));
    } else {
      throw new Error();
    }
  }
}

import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { LibHttpService } from '../../lib-http/lib-http.service';
import { LibHttp, LibHttpTypes } from '../../lib-http/models/lib-http.model';
import { LibStoreSettings } from '../models/lib-store.model';
import { LibStoreParseService } from './lib-store-parse.service';

@Injectable({
  providedIn: 'root',
})
export class LibStoreGetService {
  private libHttp = inject(LibHttpService);
  private libStoreParse = inject(LibStoreParseService);

  public getById$<T>(
    recordId: string | number,
    settings: LibStoreSettings
  ): Observable<T | null> {
    if (settings.getUrl) {
      const libHttpData: LibHttp = {
        url: `${settings.getUrl}/${recordId}`,
        type: LibHttpTypes.GET,
        httpOptions: settings.libHttpOptions,
      };

      return this.libHttp
        .http$<T>(libHttpData, settings.libHttpOptionsSettings)
        .pipe(
          map((apiData) => {
            if (apiData) {
              return this.libStoreParse.parseApiData<T>(
                apiData,
                settings.loadParsing
              );
            } else {
              return null;
            }
          }),
          catchError((error) => {
            console.log(error);
            return of(null);
          })
        );
    } else {
      return of(null);
    }
  }

  public getAll$<T>(settings: LibStoreSettings): Observable<T[]> {
    if (settings.getAllUrl) {
      const httpSettings: LibHttp = {
        url: `${settings.getAllUrl}`,
        type: LibHttpTypes.GET,
        httpOptions: settings.libHttpOptions,
      };

      return this.libHttp
        .http$<T[]>(httpSettings, settings.libHttpOptionsSettings)
        .pipe(
          map((apiData) => {
            if (apiData) {
              return this.libStoreParse.parseApiDataArray<T>(
                apiData,
                settings.loadParsing
              );
            } else {
              return [];
            }
          }),
          catchError((error) => {
            console.log(error);
            return of([]);
          })
        );
    } else {
      return of([]);
    }
  }

  public getByCustomUrl$<T, U>(
    url: string,
    settings: LibStoreSettings,
    preParse?: Function | null,
    parse?: Function | null,
    postData?: U | null
  ): Observable<T[]> {
    if (url) {
      const libHttp: LibHttp = {
        url: url,
        type: postData ? LibHttpTypes.POST : LibHttpTypes.GET,
        data: postData,
        httpOptions: settings.libHttpOptions,
      };

      return this.libHttp
        .http$<T>(libHttp, settings.libHttpOptionsSettings)
        .pipe(
          map((apiData) => {
            if (apiData) {
              const preProcessedData = preParse
                ? this.libStoreParse.parseApiData(apiData, preParse)
                : apiData;
              const checkedApiData = Array.isArray(preProcessedData)
                ? preProcessedData
                : [preProcessedData];
              return this.libStoreParse.parseApiDataArray<T>(
                checkedApiData,
                parse || settings.loadParsing
              );
            } else {
              return [];
            }
          })
        );
    } else {
      return of([]);
    }
  }
}
